.jri {
  @keyframes liquidRotate {
    0% {transform: rotate(0);}
    100% {transform: rotate(360deg);}
  }

  &-liquid-wave {
    animation: liquidRotate 3s linear 10;
    background: rgba(255, 255, 255, .75);
    border-radius: 50%;
    bottom: -350px;
    height: 300px;
    left: -100px;
    opacity: 0;
    position: absolute;
    transition: all 3s ease-out;
    width: 400px;
    z-index: 1;

    &.two {
      animation: liquidRotate 5s linear infinite;
      background: rgba(255, 255, 255, 1);
      border-radius: 80%;
      bottom: -300px;
      height: 150px;
      left: 0px;
      position: absolute;
      transition: all 3s ease-out;
      width: 200px;
    }
  }

  &-navigation {
    // cursor: none;
    left: 0;
    margin: 0;
    // opacity: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 99999999;

    &--menu {
      display: grid;
      grid-template-columns: 65% 15% 20%;
      grid-row: 1;
      margin: 0;
      list-style-type: none;
      padding: 0;

      @media (min-width: 375px) {
        grid-template-columns: 60% 20% 20%;
      }
      
      @media (min-width: 768px) {
        grid-template-columns: 75% 10% 15%;
      }
      
      @media (min-width: 1024px) {
        grid-template-columns: 80% 10% 10%;
      }
     
      @media (min-width: 1440px) {
        grid-template-columns: 80% 10% 10%;
      }
      
      @media (min-width: 1920px) {
        grid-template-columns: 90% 5% 5%;
      }
      
      @media (min-width: 2560px) {
        grid-template-columns: 90% 5% 5%;
      }

      &_item {
        border-bottom: thin solid rgba(243, 236, 220, .25);
        @include FreightDispProMedium;
        color: var(--off-white);
        font-size: 12px;
        letter-spacing: 3px;
        padding: 0;
        position: relative;
        text-transform: uppercase;

        &[data-type='logo'] {
          box-sizing: border-box;
          padding-left: 10px;

          @media (min-width: 1024px) {
            padding-left: 20px;
          }
        }
        
        &[data-type='link'] {
          align-items: center;
          border: thin solid rgba(243, 236, 220, .25);
          display: flex;
          justify-content: center;
          overflow: hidden;

          @media (min-width: 768px) {
            font-size: 24px;
          }

          &[data-active] {
            color: var(--light-gray) !important;     
            
            .jri-navigation--menu_item-visit {
              color: var(--light-gray);
            }
          }

          &:hover {
            color: var(--light-gray);
            cursor: pointer;

            .jri-liquid-wave {
              bottom: -50px;
              opacity: 1;
            }

            .jri-navigation--menu_item-visit {
              color: black;
            }
          }
        }

        &-visit {
          color: var(--off-white);
          padding: 0 20px;
          text-decoration: none;
          position: relative;
          z-index: 9;
        }

        &[data-active] {
          color: var(--light-gray) !important;         
        }
        
        &[data-menu] {      
          &:hover {
            cursor: pointer;

            .jri-navigation--menu_button-bar {
              &[data-active] {
                &:first-child {
                  width: 100%;
                }
                
                &:nth-child(2) {
                  width: 100%;
                }
                
                &:last-child {                
                  width: 100%;
                }
              }
              
              &:first-child {
                width: 40%;
              }
              
              &:nth-child(2) {
                width: 60%;
              }
              
              &:last-child {                
                width: 80%;
              }
            }
          }
        }
      }

      &_button {
        left: 50%;
        top: 50%;
        padding: 0;
        position: relative;
        position: absolute;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 30%;

        @media (min-width: 400px) {
          width: 40%;
        }
        
        @media (min-width: 1440px) {
          width: 25%;
        }

        &-bar {
          position: absolute;
          background: var(--off-white);
          height: 2px;
          margin: 0;
          padding: 0;
          transition: all .25s ease-out;
          width: 100%;

          &[data-active] {
            background: var(--light-gray) !important;

            &:first-child {
              top: 0;
              transform: rotate(45deg);
              transform-origin: center;
            }
            
            &:nth-child(2) {
              opacity: 0;
            }
            
            &:last-child {
              top: 0;
              transform: rotate(-45deg);
              transform-origin: center;
            }
          }

          &:first-child {
            transition-delay: width .25s;
            top: -7px;
          }
          
          &:nth-child(2) {
            transition-delay: width .35s;
            top: 0;
          }
          
          &:last-child {
            transition-delay: width .45s;
            top: 7px;
          }

          @media (min-width: 480px) {
            &:first-child {
              top: -10px;
            }
            
            &:nth-child(2) {
              top: 0;
            }
            
            &:last-child {
              top: 10px;
            }
          }
        }
      }
    }
  }

  &-scroll {
    bottom: 25px;
    cursor: pointer;
    left: 50%;
    opacity: 0;
    position: fixed;
    transform: translateX(-50%);
    z-index: 999999;

    &--button {
      background: transparent;
      border: 0;
      box-sizing: border-box;
      color: var(--off-white);
      cursor: pointer;
      font-size: 10px;
      outline: 0;
      padding: 10px 25px 60px;
      text-transform: uppercase;

      &:after  {
        background: var(--off-white);
        content: '';
        height: 40px;
        left: 50%;
        position: absolute;
        top: 40px;
        transform: translateX(-50%);
        width: 1px;
      }
    }
  }
}