.jri {
  canvas[data-type='liquid'] {
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  &-banner {
    height: 100vh;
    position: relative;
    width: 100vw;

    &--intro {
      @include FreightDispProBookItalic;
      color: var(--primary);
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 3px;
      margin: 0;
      padding: 0;
      position: relative;
      transition: all .25s ease-out;
      width: 90%;

      @media (min-width: 400px) {
        font-size: 24px;
        width: 90%;
      }

      @media (min-width: 768px) {
        font-size: 24px;
        width: 70%;
      }
      
      @media (min-width: 1680px) {
        font-size: 30px;
        width: 60%;
      }
      
      // @media (min-width: 1920px) {
      //   width: 75%;
      // }
    }

    &--wrapper {
      box-sizing: border-box;
      left: 50%;
      opacity: 0;
      padding: 0 15px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -20%);

      @media (min-width: 540px) {
        width: 90%;
      }

      @media (min-width: 768px) {
        top: 50%;
        width: 90%;
      }
      
      @media (min-width: 1024px) {
        width: 80%;
      }
    }

    &--heading {
      position: relative;

      @media (min-width: 540px) {
        margin-bottom: 10px;
      }

      &_text {
        @include MadroneStdRegular;
        font-size: 28px;
        line-height: 1.25;
        margin: 0;
        padding: 0;
        text-transform: uppercase;

        @media (min-width: 280px) {
          font-size: 28px;
          margin-bottom: 10px;
        }

        @media (min-width: 375px) {
          font-size: 34px;
        }
        
        @media (min-width: 400px) {
          font-size: 38px;
        }
        
        @media (min-width: 768px) {
          font-size: 64px;
        }
        
        @media (min-width: 1024px) {
          font-size: 76px;
        }
        
        @media (min-width: 1440px) {
          font-size: 120px;
        }

        @supports (-webkit-text-stroke: 1px var(--off-white)) {
          -webkit-text-stroke: 1px var(--off-white);
          -webkit-text-fill-color: transparent;
        }
        color: var(--off-white);
      }
    }
  }
}