@mixin FreightDispProBlack {
  font-family: freight-display-pro, serif;
  font-weight: 900;
  font-style: normal;
}

@mixin FreightDispProBlackItalic {
  font-family: freight-display-pro, serif;
  font-weight: 900;
  font-style: italic;
}  

@mixin FreightDispProBold {
  font-family: freight-display-pro, serif;
  font-weight: 700;
  font-style: normal;
}  
  
@mixin FreightDispProBoldItalic {
  font-family: freight-display-pro, serif;
  font-weight: 700;
  font-style: italic;
}
  
  
@mixin FreightDispProBook {
  font-family: freight-display-pro, serif;
  font-weight: 400;
  font-style: normal;
}
  
  
@mixin FreightDispProBookItalic {
  font-family: freight-display-pro, serif;
  font-weight: 400;
  font-style: italic;
}
  
  
@mixin FreightDispProLight {
  font-family: freight-display-pro, serif;
  font-weight: 300;
  font-style: normal;
}
  
@mixin FreightDispProLightItalic {
  font-family: freight-display-pro, serif;
  font-weight: 300;
  font-style: italic;
}
  
  
@mixin FreightDispProMedium {
  font-family: freight-display-pro, serif;
  font-weight: 500;
  font-style: normal;
}  
  
@mixin FreightDispProMediumItalic {
  font-family: freight-display-pro, serif;
  font-weight: 500;
  font-style: italic;
}
  
  
@mixin FreightDispProSemibold {
  font-family: freight-display-pro, serif;
  font-weight: 600;
  font-style: normal;
}
  
@mixin FreightDispProSemiboldItalic {
  font-family: freight-display-pro, serif;
  font-weight: 600;  
  font-style: italic;
}

@mixin MadroneStdRegular {
  font-family: madrone-std, serif;
  font-weight: 400;
  font-style: normal;
}

