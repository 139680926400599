.jri {
  &-special {
    background: url('../images/jriley-events-bg.jpg') top center no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    width: 100vw;
    z-index: 999999;

    @media (min-width: 375px) {
      // background-position: -100px 0;
    }

    &--events {
      // border: thin solid red;
      box-sizing: border-box;
      left: 50%;
      position: absolute;
      top: 55%;
      transform: translate(-50%, -50%);
      width: 90%;

      @media (min-width: 375px) {
        top: 50%;
      }

      @media (min-width: 1440px) {
        width: 70%;
      }

      &_heading {
        @include MadroneStdRegular;
        color: var(--off-white);
        font-size: 28px;
        
        @media (min-width: 375px) {
          font-size: 32px;
        }
        
        @media (min-width: 400px) {
          font-size: 36px;
        }
        
        @media (min-width: 768px) {
          font-size: 48px;
        }

        @media (min-width: 1024px) {
          font-size: 64px;
        }

        @media (min-width: 1440px) {
          font-size: 100px;
        }
        
        // @media (min-width: 1680px) {
        //   font-size: 82px;
        // }
  
        @supports (-webkit-text-stroke: 1px var(--off-white)) {
          -webkit-text-stroke: 1px var(--off-white);
          -webkit-text-fill-color: transparent;
        }

        text-transform: uppercase;
      }
      
      &_dates {
        display: flex;
        margin: 20px 0;
        position: relative;

        &-month {
          // border: thin solid red;
          color: var(--off-white);
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          
          &_abrev {
            @include MadroneStdRegular;
            font-size: 34px;
            line-height: .75;
            margin: 0;
            padding: 0;
            text-transform: uppercase;

            @media (min-width: 768px) {
              font-size: 44px;
            }
          }
          
          &_day {
            @include MadroneStdRegular;
            font-size: 60px;
            margin: 0;
            padding: 0;
            text-transform: uppercase;

            @media (min-width: 768px) {
              font-size: 70px;
            }
          }

          // @media (min-width: 1440px) {
          //   font-size: 24px;
          // }
        }
        
        &-release {
          // border: thin solid red;
          color: var(--off-white);
          font-size: 18px;
          margin: 0 0 0 20px;

          &_heading {
            @include FreightDispProBoldItalic;
            font-size: 18px;
            margin: -4px 0 0 0;
            line-height: 1;
            padding: 0;
            text-transform: uppercase;

            @media (min-width: 375px) {
              font-size: 21px;
            }
            
            @media (min-width: 768px) {
              font-size: 38px;
            }
          }
          
          &_subheading {
            @include FreightDispProBookItalic;
            font-size: 14px;
            line-height: 2;
            margin: 0;
            padding: 0;

            @media (min-width: 768px) {
              font-size: 28px;
            }
          }

          @media (min-width: 375px) {
            margin: 0 0 0 30px;
          }
        }
      }
    }
  }
}