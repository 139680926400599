.jri {
  &-footer {
    background: var(--off-white);
    box-sizing: border-box;
    padding: 30px;
    position: relative;
    width: 100vw;
    z-index: 999999;

    @media (min-width: 1024px) {
      padding: 50px 80px;
    }

    &--logo {
      // border: thin solid red;
      @include FreightDispProBold;
      box-sizing: border-box;
      color: var(--light-gray);
      font-size: 18px;
      letter-spacing: 4px;
      padding: 0;

      @media (min-width: 1024px) {
        padding: 50px 35px;
      }
    }
    
    &--contact {
      display: flex;
      flex-direction: column;

      @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 2fr 2.5fr 2fr 3fr .75fr;
        grid-row: 1;
      }

      &_info {
        @include FreightDispProBoldItalic;
        font-size: 16px;
        padding: 25px 0;
        position: relative;

        @media (min-width: 1024px) {
          padding: 30px;
        }

        &-header {
          @include FreightDispProBold;
          font-size: 24px;
          margin: 0;
          padding: 0;
        }
        
        &-details {
          @include FreightDispProBoldItalic;
          color: var(--light-gray);
          font-size: 16px;
          margin: 0;
          padding: 0;
          text-decoration: none;
        }

        &-sitemap {
          box-sizing: border-box;
          display: flex;
          list-style-type: none;
          margin: 0;
          padding: 0;

          &_link {
            @include FreightDispProBoldItalic;
            color: var(--light-gray);
            font-size: 12px;
            padding: 0 10px;
            text-decoration: none;

            @media (min-width: 1024px) {
              font-size: 16px;
            }

            &:first-child {
              padding-left: 0;
            }
          }
        }
        
        &-social {
          display: flex;
          flex-grow: 1;
          left: 50%;
          list-style-type: none;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);

          &_icon {
            flex-grow: 1;
            margin: 0 7px;
            width: 25px;
          }
        }
      }
    }

    &--studiosupermassive {
      // border: thin solid red;
      @include FreightDispProBold;
      box-sizing: border-box;
      color: var(--light-gray);
      display: block;
      font-size: 12px;
      padding: 50px 0;
      text-align: center;
      text-decoration: none;

      @media (min-width: 1024px) {
        font-size: 14px;
        text-align: right;
      }
    }
  }
}