.jri {
  &-dining {
    background: url('../images/jriley-eat-bg.jpg') top center no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    width: 100vw;
    z-index: 999999;

    @media (min-width: 1024px) {
      display: flex;
      flex-direction: row-reverse;
    }

    &--image {
      background: url(../images/jriley-eat-fg.jpg) top center no-repeat;
      background-size: cover;
      left: 50%;
      height: 60vh;
      position: absolute;
      transform: translateX(-50%);
      width: 90vw;
      top: 7vh;
      z-index: 1;

      @media (min-width: 375px) 
      and (min-height: 812px) {
        height: 62vh;
      }

      @media (min-width: 1024px) {
        height: 80vh;
        left: auto;
        right: 5%;
        top: 75%;
        transform: translateY(-50%);
        width: 35%;
      }
    }

    &--text {
      color: var(--off-white);
      left: 50%;
      position: absolute;
      top: 80%;
      transform: translate(-50%, -50%);
      width: 90vw;
      z-index: 2;

      @media (min-width: 1024px) {
        left: 12%;
        top: 65%;
        transform: translateY(-50%);;
        width: 50%;
      }

      &_heading {
        @include MadroneStdRegular;
        font-size: 14px;
        line-height: 1.65;
        margin: 0;
        padding: 0;
        text-transform: uppercase;

        @media (min-width: 375px) 
        and (min-height: 812px) {
          font-size: 16px;
        }

        @media (min-width: 400px) {
          font-size: 18px;
        }
        
        @media (min-width: 768px) {
          font-size: 34px;
          line-height: 1.75;
          top: 82%
        }
        
        @media (min-width: 1024px) {
          font-size: 25px;
        }
        
        @media (min-width: 1440px) {
          font-size: 36px;
        }
        
        @media (min-width: 1680px) {
          font-size: 42px;
        }
      }
      
      &_details {
        @include FreightDispProBookItalic;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        margin: 0;
        padding: 0;

        @media (min-width: 375px) 
        and (min-height: 812px) {
          font-size: 16px;
        }

        @media (min-width: 400px) {
          font-size: 16px;
        }
        
        @media (min-width: 768px) {
          font-size: 32px;
          line-height: 1.5;
          padding: 20px 0;
          width: 90%;
        }
        
        @media (min-width: 1024px) {
          font-size: 30px;
          padding: 20px 0;
          width: 85%;
        }
      }
    }
  }
}