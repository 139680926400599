:root {
  --primary: rgba(241, 233, 214, 1);
  --off-white: rgba(243, 236, 220, 1);
  --light-gray: rgba(44, 44, 44, 1);

  --color-text: #1E1E1E;
	--color-bg: #f1f1f1;
	--color-link: #de6565;
	--color-link-hover: #1E1E1E;
	--color-menu-stroke: #1E1E1E;
	--color-menu-item: #1E1E1E;
	--color-explore: #1E1E1E;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: scroll;
	overflow-x: hidden;
}