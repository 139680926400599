.jri {
  &-dining {
    background-color: rgb(211, 255, 211);
    height: 100vh;
    position: relative;
    width: 100vw;
  }
  
  &-about {
    background-color: rgb(209, 209, 255);
    height: 100vh;
    position: relative;
    width: 100vw;
  }
}

html {
  scroll-snap-type: y mandatory;
}

.panel {
  scroll-snap-align: start;
}