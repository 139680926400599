.jri {
  &-drag--cursor {
    border: thin solid white;
    border-radius: 100%;
    height: 250px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 250px;
    z-index: 9999999;

    &_text {
      color: white;
      font-size: 48px;
      margin: 0;
      padding: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      position: absolute;
    }
  }

  &-drinks {
    background: url('../images/jriley-products-bg.jpg') top center no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    width: 100vw;
    z-index: 999999;

    @media (min-width: 1024px) {
      background-image: url('../images/jriley-products-bg.jpg'), url('../images/jriley-products-whiskey-bg.jpg'), url('../images/jriley-products-mixes-bg.jpg');
      background-repeat: no-repeat, no-repeat;
      background-position: 0 0, 100vw 0, 200vw 0;
      background-size: 100%, 100%;
    }

    .cursor {
      opacity: 0;
    }
    
    &--menu {
      @include MadroneStdRegular;
      // border: thin solid red;
      color: var(--off-white);
      left: 50%;
      opacity: 1;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all .45s ease-out;
      z-index: 10;

      @supports (-webkit-text-stroke: 1px var(--off-white)) {
        -webkit-text-stroke: 1px var(--off-white);
        -webkit-text-fill-color: transparent;
      }

      &[data-active="true"] {
        opacity: 0 !important;
        z-index: 0;
      }
      
      &[data-active="false"] {
        opacity: 1 !important;
      }

      &_type {
        font-size: 24px;
        margin: 0;
        padding: 20px 0;
        text-transform: uppercase;

        @media (min-width: 375px) {
          font-size: 28px;
        }
        
        @media (min-width: 400px) {
          font-size: 32px;
        }

        @media (min-width: 768px) {
          font-size: 60px;
        }

        @media (min-width: 1440px) {
          font-size: 120px;
        }

        &:hover {
          cursor: pointer;
          -webkit-text-fill-color: var(--off-white);
          color: var(--off-white);
        }
      }

      &_exit {
        background: none;
        border: 0;
        bottom: 120px;
        color: white;
        display: none;
        font-size: 18px;
        margin: 0;
        left: 25px;
        padding: 0 0 0 30px;
        position: absolute;
        text-transform: uppercase;
        z-index: 99999;

        @media (min-width: 1024px) {
          left: 250px;
        }

        &:hover {
          cursor: pointer;
        }

        &[data-active='true'] {
          display: block;
        }
      }
    }
  }
}