.jri {
  &-about {
    background: var(--off-white) url('../images/jriley-about-bg.jpg') top left no-repeat;
    background-size: 70vw 40vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-block-start: 2vh;
    padding-inline-start: 3vh;
    position: relative;
    width: 100vw;
    z-index: 999999;

    @media (min-width: 768px) {
      background-size: 70vw 40vh;
      padding-block-start: 4vh;
      padding-inline-start: 4vh;
    }
    
    @media (min-width: 1024px) {
      background-size: 40vw 100vh;
      flex-direction: row;
    }

    &--image {
      background: url('../images/jriley-about-fg.jpg') top center no-repeat;
      background-size: cover;
      height: 40vh;
      margin-top: 10%;
      width: 90vw;


      @media (min-width: 768px) {
        height: 40vh;
        width: 90vw;
      }
      
      @media (min-width: 1024px) {
        height: 80vh;
        width: 40vw;
      }
    }

    &--details {
      // border: thin solid red;
      height: 50vh;
      line-height: 1;
      margin-left: 0;
      margin-top: 10px;
      position: relative;
      width: 85vw;
      z-index: 1;

      @media (min-width: 768px) {
        line-height: 1;
        margin-left: 0px;
        margin-top: 0px;
        position: relative;
        width: 80vw;
        z-index: 1;
      }
      
      @media (min-width: 1024px) {
        height: auto;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 45vw;
      }
      
      @media (min-width: 1440px) {
        height: auto;
        left: 48%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50vw;
      }

      &_header {
        // border: thin solid red;
        line-height: 1.2;
        
        &--line {
          @include MadroneStdRegular;
          color: transparent;
          font-size: 30px;
          margin: 0;
          padding: 0;
          text-align: left;
          text-transform: uppercase;

          @media (min-width: 400px) {
            font-size: 34px;
          }
          
          @media (min-width: 768px) {
            font-size: 64px;
          }
          
          @media (min-width: 1024px) {
            font-size: 64px;
          }
          
          @media (min-width: 1440px) {
            font-size: 80px;
          }
  
          @supports (-webkit-text-stroke: 1px var(--light-gray)) {
            -webkit-text-stroke: 1px var(--light-gray);
            -webkit-text-fill-color: transparent;
          }
          color: var(--light-gray);
        }
      }

      &_copy {
        box-sizing: border-box;
        padding: 0;

        @media (min-width: 768px) {
          padding: 0;
        }

        &--subheader {
          @include FreightDispProBoldItalic;
          font-size: 16px;
          padding: 10px 0;
          margin: 0;
          text-transform: uppercase;

          @media (min-width: 400px) {
            font-size: 18px;
            padding: 20px 0 10px;
          }
          
          @media (min-width: 768px) {
            font-size: 28px;
            padding: 20px 0;
          }
          
          @media (min-width: 1024px) {
            font-size: 30px;
            padding: 20px 0;
          }
        }
        
        &--text {
          @include FreightDispProBookItalic;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
          padding: 0;
          margin: 0;

          @media (min-width: 400px) {
            font-size: 16px;
          }
          
          @media (min-width: 768px) {
            font-size: 24px;
          }
        }
      }
    }
  }
}