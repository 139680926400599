.cursor {
	display: none;
}

.jri-drag--cursor {
	@include FreightDispProBold;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--off-white);
	color: var(--off-white);
	display: flex;
	font-size: 24px;
	left: 50%;
	margin: 0;
	padding: 0;
	position: absolute;
	text-transform: uppercase;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 175px;
	width: 175px;
	z-index: 9999;
}

.jri-drinks--wrapper {
	// cursor: none;
	pointer-events: none;
	left: 100vw;
	position: absolute;
	top: 0;
	transition: all .45s ease-out;;
	width: 100%;
	z-index: 9;

	&[data-active="false"] {
		left: 100vw;
	}
	
	@media (min-width: 1440px) {
		&[data-active="false"] {
			left: 80vw;
		}
		
		&[data-active="true"] {
			left: 0;
		}
	}
	
	&[data-active="true"] {
		left: 0;
	}

	.jri-slider {
		&--current_overlay {
			background: rgb(44,44,44);
			background: -moz-linear-gradient(90deg, rgba(44,44,44,1) 0%, rgba(44,44,44,0) 50%, rgba(44,44,44,1) 100%);
			background: -webkit-linear-gradient(90deg, rgba(44,44,44,1) 0%, rgba(44,44,44,0) 50%, rgba(44,44,44,1) 100%);
			background: linear-gradient(90deg, rgba(44,44,44,1) 0%, rgba(44,44,44,0) 50%, rgba(44,44,44,1) 100%);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 11;
		}
	
		&--current_wrapper{
			bottom: 60px;
			position: absolute;
			right: 0;
			height: 160px;
			overflow: hidden;
			width: 50vw;
			z-index: 10;
	
			@media (min-width: 1024px) {
				width: 30vw;
			}
		}
	
		&--current {
			@include MadroneStdRegular;
			top: 0;
			color: var(--off-white);
			display: flex;
			font-size: 120px;
			margin: 0;
			padding: 0;
			position: absolute;
			transition: all .45s ease-out .15s;
			left: 140px;
	
			&_position {
				// border: thin solid white;
				margin: 0;
				opacity: .5;
				padding: 0;
				text-align: center;
				width: 175px;

				&[data-active='true'] {
					opacity: 1;
				}
				
				&[data-active='false'] {
					opacity: .5;

					@supports (-webkit-text-stroke: 1px var(--off-white)) {
						-webkit-text-stroke: 1px var(--off-white);
						-webkit-text-fill-color: transparent;
					}
				}
			}
		}
	}
}

.menu-wrap {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	pointer-events: none;
}

.page--preview ~ .menu-wrap {
	pointer-events: auto;
}

.menu-draggable {
  height: 100%;
	left: 0;
  position: absolute;
	top: 0;
	// top: 50%;
	// transform: translateY(-50%);
  width: 100%;
	cursor: grab;
}

.menu-draggable:active {
	cursor: grabbing;
}

.menu {
	background: rgba(44, 44, 44, 1);
	display: flex;
	width: 100vw;
	height: 100vh;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	width: -moz-fit-content;
	width: fit-content;
	counter-reset: menu-number;
	pointer-events: none;
	will-change: transform;
}

.menu__item--title {
	@include MadroneStdRegular;
	color: var(--off-white);
	font-size: 26px;
	text-transform: uppercase;
	text-align: left;

	@supports (-webkit-text-stroke: 1px var(--off-white)) {
		-webkit-text-stroke: 1px var(--off-white);
		-webkit-text-fill-color: transparent;
	}

	@media (min-width: 375px) {
		font-size: 28px;
	}
	
	@media (min-width: 400px) {
		font-size: 32px;
	}
	
	@media (min-width: 768px) {
		font-size: 60px;
	}
	
	@media (min-width: 1024px) {
		font-size: 55px;
	}
	
	@media (min-width: 1280px) {
		font-size: 72px;
	}
	
	@media (min-width: 1440px) {
		font-size: 72px;
	}
}

.menu__item--description {
	@include FreightDispProMediumItalic;
	color: var(--off-white);
	font-size: 16px;
	text-align: left;
	padding: 25px 10px;

	@media (min-width: 375px) {
		font-size: 20px;
	}
	
	@media (min-width: 400px) {
		font-size: 24px;
	}

	@media (min-width: 768px) {
		font-size: 24px;
	}
	
	@media (min-width: 1024px) {
		padding: 25px 0 25px 10px;
	}

	@media (min-width: 1440px) {
		padding: 25px 0 25px 10px;
	}
}

.menu__item {
	// border: thin solid red;
	--counter-opacity: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	color: var(--color-menu-item);
	height: 100vh;
	position: relative;
	text-align: center;
	margin: 0;
	padding: 0;
	user-select: none;
	width: 100vw;

	@media (min-width: 1440px) {
		width: 85vw;
	}

	&--inner {
		// border: thin solid red;
		left: 50%;
		position: absolute;
		top: 45%;
		transform: translate(-50%, -50%);

		@media (min-width: 1440px) {
			left: 60%;
			top: 40%;
		}
	}
}

.menu__item[data-product="whiskey"] {
	background: url('../images/jriley-products-1775.jpg') no-repeat;
	background-position: -125px 50px;
	background-size: cover;
	z-index: 1;

	@media (min-width: 1440px) {
		background-position: -90px 0;
	}
}

.menu__item[data-product="bourbon"] {
	background: url('../images/jriley-products-bourbon.jpg') no-repeat;
	background-position: -60px 80px;
	background-size: cover;
	z-index: 2;

	@media (min-width: 1440px) {
		background-position: -100px 0;
	}
}

.menu__item[data-product="rye-whisky"] {
	background: url('../images/jriley-products-irish.jpg') no-repeat;
	background-position: -125px 80px;
	background-size: cover;
	z-index: 3;

	@media (min-width: 1440px) {
		background-position: -125px 0;
	}
}

.menu__item[data-product="hoptrocity"] {
	background: url('../images/jriley-products-hoptrocity.jpg') no-repeat;
	background-position: -125px 80px;
	background-size: cover;
	z-index: 4;

	@media (min-width: 1440px) {
		background-position: -80px 0;
	}
}

.menu__item[data-product="moonshine"] {
	background: url('../images/jriley-products-moonshine.jpg') no-repeat;
	background-position: -125px 80px;
	background-size: cover;
	z-index: 10;

	@media (min-width: 1440px) {
		background-position: -80px 0;
	}
}

.menu__item[data-product="gin"] {
	background: url('../images/jriley-products-gin.jpg') no-repeat;
	background-position: -125px 80px;
	background-size: cover;
	z-index: 10;

	@media (min-width: 1440px) {
		background-position: -80px 0;
	}
}

.menu__item[data-product="vodka"] {
	background: url('../images/jriley-products-vodka.jpg') no-repeat;
	background-position: -125px 80px;
	background-size: cover;
	z-index: 10;

	@media (min-width: 1440px) {
		background-position: -80px 0;
	}
}

.menu__item[data-product="sour"] {
	background: url('../images/jriley-mixes-sour.jpg') no-repeat;
	background-position: -125px 80px;
	background-size: cover;
	z-index: 10;

	@media (min-width: 1440px) {
		background-position: -235px 0;
	}
}

.menu__item[data-product="sunset"] {
	background: url('../images/jriley-mixes-sunset.jpg') no-repeat;
	background-position: -125px 80px;
	background-size: cover;
	z-index: 10;

	@media (min-width: 1440px) {
		background-position: -80px 0;
	}
}

.page--preview ~ .menu-wrap .menu__item {
	--counter-opacity: 1;
}

.menu__item--current {
	color: var(--color-menu-item);
}

.menu__item-link {
	color: currentColor;
	font-weight: bold;
	font-size: 12vw;
	display: flex;
	line-height: 1.2;
}

.menu__item-explore {
	cursor: pointer;
	margin: 0.5rem 0 0 0;
	display: inline-block;
	color: var(--color-explore);
	text-decoration: underline;
	opacity: 0;
	padding: 0.5rem 0.5rem 0;
	will-change: transform;
}

.menu__item-explore:hover,
.menu__item-explore:focus {
	text-decoration: none;
}

.page--preview ~ .menu-wrap .menu__item--current .menu__item-explore {
	pointer-events: auto;
}

.letter {
	position: relative;
	overflow: hidden;
	display: inline-block;
}

.letter__inner {
	display: block;
	will-change: transform;
}

.letter__inner--stroke {
	position: absolute;
	left: 100%;
	top: 0;
	-webkit-text-stroke: 1px var(--color-menu-stroke);
	text-stroke: 1px var(--color-menu-stroke);
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
}

.menu__item--current .letter__inner {
	transform: translate3d(-100%,0,0);
}

.grid-wrap {
	display: grid;
	margin: 0 auto;
	grid-template-columns: 100%;
	grid-template-rows: 3rem 1fr;
	position: relative;
	padding: 13rem 5vw 2rem;
	pointer-events: none;
}

.gridback {
	align-self: start;
	grid-area: 1 / 1 / 2 / 2;
	justify-self: center;
	background: none;
	border: 0;
	margin: 0;
	padding: 0;
	color: #fff;
	opacity: 0;
	pointer-events: auto;
}

.page--preview .gridback {
	pointer-events: none;
}

.gridback:hover {
	color: var(--color-link-hover);
}

.gridback:focus {
	outline: none;
}

.grid {
	grid-area: 2 / 1 / 3 / 2;
	--gridgap: 1vw;
	--gridwidth: 100%;
	--gridheight: 80vw;
	display: grid;
	width: var(--gridwidth);
	height: var(--gridheight);
	grid-template-rows: repeat(10,calc(var(--gridheight) / 10 - var(--gridgap)));
	grid-template-columns: repeat(10,calc(var(--gridwidth) / 10 - var(--gridgap)));
	grid-gap: var(--gridgap);
	align-content: center;
    	justify-content: center;
}

.grid__item-wrap {
	position: relative;
	will-change: transform;
}

.grid__item {
	opacity: 0;
	position: relative;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	will-change: transform;
	-webkit-filter: grayscale(0) contrast(1) brightness(1);
	filter: grayscale(0) contrast(1) brightness(1);
}

.page--preview .grid__item {
	-webkit-filter: grayscale(0.5) contrast(0.4) brightness(1.5);
	filter: grayscale(0.5) contrast(0.4) brightness(1.5);
}

/* Layout 1 */
.grid--layout-1 .grid__item-wrap:first-child {grid-area: 3 / 1 / 8 / 4;}
.grid--layout-1 .grid__item-wrap:nth-child(2) {grid-area: 1 / 2 / 3 / 4;}
.grid--layout-1 .grid__item-wrap:nth-child(3) {grid-area: 5 / 4 / 8 / 8; }
.grid--layout-1 .grid__item-wrap:nth-child(4) {grid-area: 2 / 7 / 5 / 11;}
.grid--layout-1 .grid__item-wrap:nth-child(5) {grid-area: 1 / 4 / 5 / 7;}
.grid--layout-1 .grid__item-wrap:nth-child(6) {grid-area: 8 / 5 / 11 / 2;}
.grid--layout-1 .grid__item-wrap:nth-child(7) {grid-area: 10 / 9 / 8 / 11;}
.grid--layout-1 .grid__item-wrap:nth-child(8) {grid-area: 5 / 8 / 8 / 10;}
.grid--layout-1 .grid__item-wrap:nth-child(9) {grid-area: 8 / 5 / 11 / 9;}


/* Layout 2 */
.grid--layout-2 .grid__item-wrap:first-child { grid-area: 2 / 1 / 5 / 4; }
.grid--layout-2 .grid__item-wrap:nth-child(2) { grid-area: 1 / 4 / 4 / 7; }
.grid--layout-2 .grid__item-wrap:nth-child(3) {grid-area: 1 / 7 / 5 / 10;}
.grid--layout-2 .grid__item-wrap:nth-child(4) {grid-area: 5 / 1 / 7 / 4;}
.grid--layout-2 .grid__item-wrap:nth-child(5) {grid-area: 4 / 4 / 7 / 7;}
.grid--layout-2 .grid__item-wrap:nth-child(6) {grid-area: 7 / 7 / 11 / 4;}
.grid--layout-2 .grid__item-wrap:nth-child(7) {grid-area: 5 / 7 / 8 / 11;}
.grid--layout-2 .grid__item-wrap:nth-child(8) {grid-area: 7 / 2 / 9 / 4;}

/* Layout 3 */
.grid--layout-3 .grid__item-wrap:first-child {grid-area: 1 / 2 / 3 / 5;}
.grid--layout-3 .grid__item-wrap:nth-child(2) {grid-area: 3 / 1 / 6 / 5;}
.grid--layout-3 .grid__item-wrap:nth-child(3) {grid-area: 1 / 5 / 5 / 8;}
.grid--layout-3 .grid__item-wrap:nth-child(4) {grid-area: 2 / 8 / 6 / 11;}
.grid--layout-3 .grid__item-wrap:nth-child(5) {grid-area: 5 / 5 / 8 / 8;}
.grid--layout-3 .grid__item-wrap:nth-child(6) {grid-area: 6 / 8 / 8 / 11;}
.grid--layout-3 .grid__item-wrap:nth-child(7) {grid-area: 6 / 2 / 8 / 5;}
.grid--layout-3 .grid__item-wrap:nth-child(8) {grid-area: 11 / 4 / 8 / 7;}
.grid--layout-3 .grid__item-wrap:nth-child(9) {grid-area: 8 / 9 / 11 / 7;}

/* Layout 4 */
.grid--layout-4 .grid__item-wrap:first-child {grid-area: 2 / 1 / 4 / 4;}
.grid--layout-4 .grid__item-wrap:nth-child(2) {grid-area: 1 / 4 / 3 / 7;}
.grid--layout-4 .grid__item-wrap:nth-child(3) {grid-area: 3 / 4 / 5 / 7;}
.grid--layout-4 .grid__item-wrap:nth-child(4) {grid-area: 1 / 7 / 4 / 11;}
.grid--layout-4 .grid__item-wrap:nth-child(5) {grid-area: 4 / 2 / 7 / 4;}
.grid--layout-4 .grid__item-wrap:nth-child(6) {grid-area: 5 / 7 / 8 / 4;}
.grid--layout-4 .grid__item-wrap:nth-child(7) {grid-area: 4 / 7 / 8 / 11;}
.grid--layout-4 .grid__item-wrap:nth-child(8) {grid-area: 8 / 9 / 11 / 4;}

/* Layout 5 */
.grid--layout-5 .grid__item-wrap:first-child {grid-area: 2 / 1 / 5 / 4;}
.grid--layout-5 .grid__item-wrap:nth-child(2) {grid-area: 1 / 4 / 5 / 7;}
.grid--layout-5 .grid__item-wrap:nth-child(3) {grid-area: 5 / 2 / 7 / 5;}
.grid--layout-5 .grid__item-wrap:nth-child(4) {grid-area: 1 / 7 / 4 / 11;}
.grid--layout-5 .grid__item-wrap:nth-child(5) {grid-area: 5 / 7 / 7 / 5;}
.grid--layout-5 .grid__item-wrap:nth-child(6) {grid-area: 7 / 5 / 10 / 1;}
.grid--layout-5 .grid__item-wrap:nth-child(7) {grid-area: 4 / 7 / 7 / 9;}
.grid--layout-5 .grid__item-wrap:nth-child(8) {grid-area: 4 / 9 / 9 / 11;}
.grid--layout-5 .grid__item-wrap:nth-child(9) {grid-area: 7 / 5 / 11 / 9;}

.page--preview {
	position: relative;
	overflow: hidden;
	height: 100vh;
	pointer-events: none;
}

@media screen and (min-width: 53em) {
	.frame {
		position: fixed;
		text-align: left;
		z-index: 10000;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 2.5rem 3rem;
		grid-template-columns: 20rem 1fr 1fr;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links pagetitle'
							'... ... ...'
							'... ... social';
	}
	.frame__pagetitle {
		grid-area: pagetitle;
		margin: 0;
	}
	.frame__title-wrap {
		grid-area: title;
		display: flex;
	}
	.frame__title {
		margin: 0;
	}
	.frame__tagline {
		position: relative;
		margin: 0 0 0 1rem;
		padding: 0 0 0 1rem;
	}
	.frame__social {
		margin: 0;
		grid-area: social;
		justify-self: end;
	}
	.frame__links {
		grid-area: links;
		padding: 0;
		justify-self: start;
	}
	.grid-wrap {
		grid-template-rows: 1.25rem 1fr;
		grid-gap: 3rem;
		padding: 2.5rem 3rem;
	}
	.grid {
		padding: 0 10vw;
	}
	.gridback {
		justify-self: end;
	}
}

@media (any-pointer: fine) {
	.cursor {
		display: none;
	}
	.cursor__inner {
		z-index: 9999;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		mix-blend-mode: difference;
		border-radius: 50%;
	}
	.cursor__side {
		position: absolute;
		top: 50%;
		width: 5px;
		height: 1px;
		background: #de6565;
		opacity: 0;
	}
	.cursor__side--left {
		right: calc(100% + 5px);
	}
	.cursor__side--right {
		left: calc(100% + 5px);
	}
	.cursor__inner--circle {
		width: 25px;
		height: 25px;
		border: 1px solid #de6565;
	}
}
