.jri {
  &-mega {
    background: var(--off-white) url('../images/jriley-menu-bg.jpg') top center no-repeat;
    background-size: contain;
    cursor: none;
    display: none;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all .5s ease-out;
    width: 100vw;
    z-index: 9999999;

    &--cursor {
      background: rgba(255, 255, 255, .15);
      border: 2px solid #242424;
      border-radius: 50%;
      display: none;
      height: 40px;
      pointer-events: none;
      position: absolute;
      transition: width .30s ease-out, height .30s ease-out;
      transform: translate(-50%, -50%);
      width: 40px;
      z-index: 99999999;

      @media (min-width:1440px) {
        display: block;
      }

      &[data-active] {
        height: 100px;
        width: 100px;
      }
    }

    &--menu {
      cursor: none;
      left: 50%;
      position: absolute;
      top: 50%;
      list-style-type: none;
      margin: 0;
      padding: 0;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;

      @media (min-width:768px) {
        width: 60%;
      }
     
      @media (min-width:1440px) {
        width: 70%;
      }

      &_item-link {
        @include MadroneStdRegular;
        box-sizing: border-box;
        cursor: none;
        display: inline-block;
        font-size: 24px;
        line-height: 1.5;
        margin: 0;
        padding: 10px 20px;
        text-decoration: none;
        text-transform: uppercase;
        color: var(--light-gray);

        @media (min-width:768px) {
          font-size: 72px;
          line-height: 1;
          padding: 25px 20px;
        }

        @supports (-webkit-text-stroke: 1px var(--light-gray)) {
          -webkit-text-stroke: 1px var(--light-gray);
          -webkit-text-fill-color: transparent;
        }

        &:hover {
          @supports (-webkit-text-stroke: 1px var(--light-gray)) {
            -webkit-text-stroke: 1px var(--light-gray);
            -webkit-text-fill-color: 1px var(--light-gray);
          }
        }
      }
    }

    &[data-active='true'] {
      opacity: 1;
    }
    
    &[data-active='false'] {
      opacity: 0;
    }
  }
}