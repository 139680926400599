.jri {
  &-logo {
    &--image {
      height: auto;
      margin: 0;
      padding: 0;
      text-align: left;
      width: 150px;

      @media (min-width: 375px) {
        width: 170px;
      }
      
      @media (min-width: 768px) {
        // width: 250px;
      }
      
      @media (min-width: 1024px) {
        // width: 225px;
      }
      
      @media (min-width: 1440px) {
        // width: 250px;
      }
    }
  }

  &-splash {
    background: url('../assets/jriley-coming-soon-bg-desktop.jpg') top center no-repeat;
    background-size: cover;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;;
    width: 100vw;
    z-index: 999999999;

    &--content {
      border: thin solid var(--off-white);
      box-sizing: border-box;
      color: var(--off-white);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;

      @media (min-width: 1440px) {
        width: 90%;
      }

      &_logo {
        box-sizing: border-box;
        padding: 0;
        position: relative;

        @media (min-width: 768px) {
          padding: 0 0 0 20px;
        }
        
        @media (min-width: 1440px) {
          padding: 0 0 0 30px;
        }

        &-image {
          height: auto;
          margin: 0;
          padding: 0;
          width: 200px;

          @media (min-width: 280px)
          and (min-height: 653px) {
            width: 200px;
          }
          
          @media (min-width: 375px) {
            width: 250px;
          }
          
          @media (min-width: 768px) {
            width: 300px;
          }
        }
      }
      
      &_header {
        @include MadroneStdRegular;
        font-size: 21px;
        line-height: 1.5;
        margin: 0;
        padding: 0 20px 10px;
        text-transform: uppercase;

        @media (min-width: 280px)
        and (min-height: 653px) {
          font-size: 18px;
        }

        @media (min-width: 375px) {
          font-size: 24px;
          padding: 0 20px;
        }
        
        @media (min-width: 375px)
        and (min-height: 812px) {
          font-size: 24px;
          padding: 0 20px;
        }

        @media (min-width: 400px) {
          font-size: 28px;
        }
        
        @media (min-width: 768px){
          font-size: 54px;
          padding: 20px 20px;
        }
        
        @media (min-width: 1024px){
          font-size: 60px;
          padding: 0 20px;
        }

        @media (min-width: 1440px) {
          font-size: 100px;
          padding: 20px 50px;
        }
        
        @media (min-width: 1680px) {
          font-size: 120px;
          padding: 0 50px;
        }
        
        @media (min-width: 2560px) {
          font-size: 160px;
          padding: 0 50px;
        }
      }
      
      &_subheader {
        @include FreightDispProBookItalic;
        font-size: 14px;
        margin: 0;
        padding: 0 25px 50px;
        width: 100%;

        @media (min-width: 375px) {
          font-size: 16px;
          padding: 20px 25px 100px;
        }
        
        @media (min-width: 400px) {
          padding: 20px 25px 70px;
        }
        
        @media (min-width: 540px) {
          padding: 20px 25px 70px;
        }

        @media (min-width: 375px)
        and (min-height: 812px) {
          font-size: 16px;
          padding: 20px 25px 70px;
        }

        @media (min-width: 400px) {
          font-size: 18px;
        }

        @media (min-width: 768px) {
          font-size: 28px;
        }

        @media (min-width: 1024px) {
          padding: 20px 50px 50px;
        }
        
        @media (min-width: 1440px) {
          padding: 20px 50px 70px;
        }
        
        @media (min-width: 1680px) {
          padding: 20px 50px 70px;
          width: 50%;
        }
        
        @media (min-width: 2560px) {
          padding: 0 50px 70px;
          width: 50%;
        }
      }
    }

    &--actions {
      border-top: thin solid var(--off-white);
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      display: flex;
      flex-flow: column;
      position: relative;
      width: 100%;

      @media (min-width: 1024px) {
        border-top: thin solid var(--off-white);
        border-right: 0;
        border-left: 0;
        border-bottom: 0;
        flex-flow: row;
      }

      &_button {
        @include FreightDispProBold;
        background: 0;
        box-sizing: border-box;
        border: 0;
        color: var(--off-white);
        outline: 0;
        overflow: hidden;
        font-size: 14px;
        letter-spacing: 4px;;
        margin: 0;
        padding: 40px 25px;
        position: relative;
        transition: top .5s ease-out, color 1s ease-out .5s;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @media (min-width: 768px) {
          font-size: 24px;
          padding: 60px 25px;
        }

        @media (min-width: 1024px) {
          font-size: 18px;
          width: 50%;
        }
        
        @media (min-width: 1440px) {
          font-size: 20px;
          width: 50%;
        }

        &[data-enter], &[data-visit] {
          &:before, &:after {
            content: '';
            height: 1000px;
            left: -50%;
            opacity: 0;
            position: absolute;
            top: 140%;
            transition: all 2s ease-out;
            width: 1000px;
            z-index: -1;

            @media (min-width: 1920px) {
              height: 1500px;
              width: 1500px;
            }
          }          
          
          &:before {
            animation: animate 2s linear infinite;
            background: rgba(255, 255, 255, 1);
            border-radius: 45%;
          }          
          
          &:after {
            animation: animate 2s linear infinite;
            background: rgba(255, 255, 255, .50);
            border-radius: 40%;
          }
          
          @keyframes animate {
            0% {
              transform: rotate(0);
            }
            
            100% {
              transform: rotate(360deg);
            }
          }
        }

        &:hover {
          // background: var(--off-white);
          cursor: pointer;
          color: var(--light-gray);

          &[data-enter], &[data-visit] {
            &:before, &:after {
              left: -50%;
              opacity: 1;
              top: -250%;

              @media (min-width: 768px) {
                left: -40%;
              }

              @media (min-width: 1440px) {
                left: -10%;
                top: -250%;
              }
            }  
          }
        }

        &:first-child {
          border-bottom: thin solid var(--off-white);

          @media (min-width: 1024px) {
            border-right: thin solid var(--off-white);
            border-bottom: 0;
          }
        }
      }
    }
  }
}